@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  background: black;
}

.App {
  background: rgb(15, 15, 15);
}

.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
}

.fade-in-fast {
  animation: fadeIn 200ms;
  -webkit-animation: fadeIn 200ms;
}

.fade-in-slow {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.option-button button {
  border: 2px solid theme('colors.purple.400');
  border-radius: 5px;
  background: theme('colors.purple.500');;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  height: 50px;
  padding: 7px 0;
  text-transform: capitalize;
  width: 96px;
}

.option-button button:hover {
  background: theme('colors.purple.300');
  color: white;
}

.option-button-active {
  background: theme('colors.purple.300');
  border-radius: 5px;
  color: white !important;
}

@media screen and (max-width: 500px)  {
  .App {
    background-size: cover;
    background-repeat: no-repeat;
  }
}